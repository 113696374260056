import './header.css'
import Header from './header';
import './forfaits2.css';
import axios from "axios";
import { useRef } from 'react';
import socleanlogo from './Pictures/thumbnail_SoClean_vitre_arriere_FINAL-120copie.png'
import React from "react";
export default function Forfaits2()
{
  const bodyRef = useRef(null);
  const [showModal, setShowModal] = React.useState(false);
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const onClick = (event) => {
    const value = event.target.value;
    sessionStorage.setItem("forfaitnom",value);
    setEmailError("");
    setNameError("");
    setShowModal(!showModal);};

    function InputNameChange(event) {
      const name = event.target.value;
      if (name.length < 2)
      {setNameError("Le prénom/nom doit comprendre au moins 2 caractères");}
      else if(/\d/.test(name)) 
      {setNameError("Le prénom/nom ne doit comprendre que des caracteres alphabetique");}
      else if (!/^[a-zA-Z '-]+$/.test(name)) 
      {setNameError("Le prénom/nom ne peux pas contenir de caracteres speciaux");}
      else
      {setNameError("");}
    }
  
    function InputEmailChange(event) {
      const email = event.target.value;
      if (email.length < 5)
      {setEmailError("L'adresse courriel doit comprendre au moins 5 caracteres");}
      else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) 
      {setEmailError("L'adresse courriel entrée n'est pas dans un format valide");}
      else
      {setEmailError("");}
    }

    function SendRequestForfait()
    {
      const firstname = document.getElementById("firstnameforfait").value;
      const lastname = document.getElementById("lastnameforfait").value;
      const email = document.getElementById("emailforfait").value;
      const tel = document.getElementById("telephoneforfait").value;
      const marque = document.getElementById("marquevehicule").value;
      const modele = document.getElementById("modelevehicule").value;
      const dispoforfait = document.getElementById("dispoforfait").value;
      const nomforfait = sessionStorage.getItem("forfaitnom");
  
      const data = {
        firstname: firstname,
        email: email,
        lastname: lastname,
        tel:tel,
        marque:marque,
        modele:modele,
        dispoforfait:dispoforfait,
        nomforfait:nomforfait
      };
  
      axios.post("https://dev.socleanesthetique.com:3001/newrequestforfait", data).then(function (response) {
            document.getElementById("firstnameforfait").value = "";
            document.getElementById("lastnameforfait").value = "";
            document.getElementById("emailforfait").value = "";
            document.getElementById("telephoneforfait").value = "";
            document.getElementById("marquevehicule").value = "";
            document.getElementById("modelevehicule").value = "";
            document.getElementById("dispoforfait").value = "";
            sessionStorage.clear();
            setShowModal(!showModal);
            }).catch(function (error)
            {
              document.getElementById("firstnameforfait").value = "";
              document.getElementById("lastnameforfait").value = "";
              document.getElementById("emailforfait").value = "";
              document.getElementById("telephoneforfait").value = "";
              document.getElementById("marquevehicule").value = "";
              document.getElementById("modelevehicule").value = "";
              document.getElementById("dispoforfait").value = "";
              sessionStorage.clear();
              setShowModal(!showModal);
              });;
    }
    return(
      <div>
        <Header></Header>
  <div class="bg-white dark:bg-gray-800 pt-20 " id="pricing_car">
  <div class="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
    <div class="mx-auto max-w-2xl lg:max-w-4xl">
      <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Trouve le parfait forfait pour ta voiture</p>
      <br></br>
      <small class="text-s font-semibold leading-8 text-gray-900 dark:text-gray-200">Prix sujet à changement selon votre voiture</small>
    </div>
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8 mb-6">
  </div>
  <div class="">
    <div class="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-7xl lg:grid-cols-3 lg:gap-8">
        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200">Lavage extérieur Signature
              </h3>
              <div class="items-center ml-2">
              </div>
            </div>
            <div class="mt-4 flex items-baseline text-4xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
            99.95 - 119.95$</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Prélavage au canon à mousse</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage à la main</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage des seuils de portes</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien des jantes en profondeur</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage des vitres</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Dégraissage et ajout de lustrant protecteur sur les pneus</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application de lustrant protecteur a toutes partie exterieur en plastique</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Dégraissage des tuyaux d’échappement</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien du Chrome (si applicable)</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Séchage du véhicule en profondeur</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Ajout d’une protection de peinture liquide</p>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button id = "forfait1" class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Lavage extérieur Signature"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>


        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-plus">Entretien Intérieur complet
              </h3>
              <div class="items-center ml-2">
              </div>
            </div>
            <div class="mt-4 flex items-baseline text-4xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
            199.95 - 214.95$</div>
              <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Aspirateur intégrale</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage des bancs</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage des vitres</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage de tous les compartiments intérieurs</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Shampoing sur les tapis</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Extraction de calcium</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Rénovateur de plastique</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Protecteur et conditionneur pour plastique et vinyle</p>
            <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage de la voûte</p>
            <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Traitement de cuir (si applicable)</p>
            <br></br>
            <br></br>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Entretien Intérieur complet"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>

        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-custom">Forfait Élite</h3>
              <div class="items-center ml-2"></div>
            </div>
            <div class="mt-4 flex items-baseline text-4xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">299.95 - 329.95$</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage exterieur signature</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien intérieur complet</p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Forfait Élite"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>

        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-custom">Forfait Shine-up</h3>
              <div class="items-center ml-2"></div>
            </div>
            <div class="mt-4 flex items-baseline text-4xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">324.99 - 364.99$</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Forfait de lavage Signature</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Décontamination complète de la peinture en deux étapes</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Polissage Shine-up</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application d'un scellant à peinture lustrant et remplissant</p>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Forfait Shine-up"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>

        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-custom">Forfait Expérience</h3>
              <div class="items-center ml-2"></div>
            </div>
            <div class="mt-4 flex items-baseline text-4xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">529.99 - 584.99$</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien intérieur complet</p>
            <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Forfait Shine-up*</p>
            <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Applicationd'un scellant à peinture (Offrant une protection jusqu'à 6 mois)</p>
            <br></br>
            <br></br>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Forfait Expérience"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>

        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-custom">Forfait SoClean</h3>
              <div class="items-center ml-2"></div>
            </div>
            <div class="mt-4 flex items-baseline text-4xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">599.99 - 639.99$</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage extérieur signature</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Correction de la peinture complète de l’extérieur du véhicule (Remise à neuf)</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application d'un scellant à peinture (Offrant une protection moyenne de 12 mois)</p>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Forfait SoClean"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>

        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-custom">Forfait Exclusif</h3>
              <div class="items-center ml-2"></div>
            </div>
            <div class="mt-4 flex items-baseline text-4xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">749.99 - 799.99$</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage extérieur signature</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien intérieur complet</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Correction intégrale de la peinture du véhicule (Remise à neuf)</p>
            <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application d'un scellant à peinture (Offrant une protection moyenne de 12 mois.)
(Pour une protection offrant une durée supérieure demander plus d’informations.)</p>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Forfait Exclusif"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{showModal && <div id="background_modal_blur">
        <div id="background_modal">
        <form class="newsletter_subscription" >
        <div class="form_newsletter">
        <button class="CloseModal" onClick={onClick}>
            X
          </button>
          <img
              class="logo_modal"
              src={socleanlogo}
            ></img>
            <br/>
          <h3 class="text_newsletter_promotional">
          Nous sommes ravis que vous ayez opté pour nos service ! Veuillez renseigner les champs ci-dessous pour finaliser la demande
          </h3>
          <br/>
          <p class="text-white txtinfo">Renseignements personnels</p>
          <div className="form_newsletter_name">
            <input
            id = "firstnameforfait"
              onChange={InputNameChange}
              class="inputInscriptionName"
              type="text"
              placeholder="Prenom"
            />
            <input
              onChange={InputNameChange}
              id = "lastnameforfait"
              class="inputInscriptionName"
              type="text"
              placeholder="Nom"
            />
          </div>
          {<span id="firstname-validation-message">{nameError}</span>}
          <div className="form_newsletter_name">
          <input
            onChange={InputEmailChange}
            id = "emailforfait"
            class="inputInscription"
            type="text"
            placeholder="Courriel"
          />
          <input
            id = "telephoneforfait"
            class="inputInscription"
            type="text"
            placeholder="Téléphone"
          />
          </div>
          <br></br>
          <p class="text-white txtinfo">Renseignements sur le véhicule</p>
          <div className="form_newsletter_name">
          <input
            id = "marquevehicule"
            class="inputInscription"
            type="text"
            placeholder="Marque"
          />
          <input
            id = "modelevehicule"
            class="inputInscription"
            type="text"
            placeholder="Modèle"
          />
          </div>
          <br></br>
          <p class="text-white txtrenseignement">Renseignements sur vos disponibilité</p>
          <textarea
            id = "dispoforfait"
            class="min-w-12"
            rows="3"
            cols="35"
            placeholder="Vos disponibilité et commentaires"
          />
          <br></br>
          {<span id="email-validation-message">{emailError}</span>}
          <button type="button" class="btnInscription" onClick={SendRequestForfait}> Envoyer ma demande</button>
        </div>
      </form>
        </div>
      </div>}
</div>
)
}