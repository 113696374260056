import './header.css'
import Header from './header';
import axios from "axios";
import './forfaits2.css';
import { useRef } from 'react';
import socleanlogo from './Pictures/thumbnail_SoClean_vitre_arriere_FINAL-120copie.png'
import React, { useState } from 'react';
export default function Forfaits5()
{
  const [piedvrValue, setPiedvrValue] = useState('');
    const [result1, setResult1] = useState(0);
    const [result2, setResult2] = useState(0);
  
    const handleInputChange = (event) => {
      const value = event.target.value;
      setPiedvrValue(value);
  
      // Effectuer le calcul mathématique ici
      setResult1(parseFloat(value) * 7.9);
      setResult2(parseFloat(value) * 20.47);
    };
  
  const bodyRef = useRef(null);
  const [showModal, setShowModal] = React.useState(false);
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const onClick = (event) => {
    const value = event.target.value;
    sessionStorage.setItem("forfaitnom",value);
    setEmailError("");
    setNameError("");
    setShowModal(!showModal);};

    function InputNameChange(event) {
      const name = event.target.value;
      if (name.length < 2)
      {setNameError("Le prénom/nom doit comprendre au moins 2 caractères");}
      else if(/\d/.test(name)) 
      {setNameError("Le prénom/nom ne doit comprendre que des caracteres alphabetique");}
      else if (!/^[a-zA-Z '-]+$/.test(name)) 
      {setNameError("Le prénom/nom ne peux pas contenir de caracteres speciaux");}
      else
      {setNameError("");}
    }
  
    function InputEmailChange(event) {
      const email = event.target.value;
      if (email.length < 5)
      {setEmailError("L'adresse courriel doit comprendre au moins 5 caracteres");}
      else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) 
      {setEmailError("L'adresse courriel entrée n'est pas dans un format valide");}
      else
      {setEmailError("");}
    }

    function SendRequestForfait()
    {
      const firstname = document.getElementById("firstnameforfait").value;
      const lastname = document.getElementById("lastnameforfait").value;
      const email = document.getElementById("emailforfait").value;
      const tel = document.getElementById("telephoneforfait").value;
      const marque = "Flotte entreprise";
      const modele = "Flotte entreprise";
      const dispoforfait = document.getElementById("dispoforfait").value;
      const nomforfait = sessionStorage.getItem("forfaitnom");
  
      const data = {
        firstname: firstname,
        email: email,
        lastname: lastname,
        tel:tel,
        marque:marque,
        modele:modele,
        dispoforfait:dispoforfait,
        nomforfait:nomforfait
      };
  
      axios.post("https://dev.socleanesthetique.com:3001/newrequestforfait", data).then(function (response) {
            document.getElementById("firstnameforfait").value = "";
            document.getElementById("lastnameforfait").value = "";
            document.getElementById("emailforfait").value = "";
            document.getElementById("telephoneforfait").value = "";
            document.getElementById("marquevehicule").value = "";
            document.getElementById("modelevehicule").value = "";
            document.getElementById("dispoforfait").value = "";
            sessionStorage.clear();
            setShowModal(!showModal);
            }).catch(function (error)
          {
            document.getElementById("firstnameforfait").value = "";
            document.getElementById("lastnameforfait").value = "";
            document.getElementById("emailforfait").value = "";
            document.getElementById("telephoneforfait").value = "";
            document.getElementById("marquevehicule").value = "";
            document.getElementById("modelevehicule").value = "";
            document.getElementById("dispoforfait").value = "";
            sessionStorage.clear();
            setShowModal(!showModal);
            });
    }

    const OnClickEvent = () => {
      
      let longueur = document.getElementById("piedvr").value;
  };

    return(
      <div>
        <Header></Header>
  <div class="bg-white dark:bg-gray-800 pt-20 " id="pricing_cie">
  <div class="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
    <div class="mx-auto max-w-2xl lg:max-w-4xl pb-5">
      <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Trouve le parfait forfait pour ta flottre d'entreprise</p>
      <br></br>
      <button onClick={onClick} value={"Soumission flotte entreprise"} id = "forfait1" class="mt-4 bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" >Obtenir une soumission</button>
    </div>
    <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Ce que les gens penses de nous</p>
    <br></br>
    <div class="flex flex-wrap justify-between">
    <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Michel Lapointe</h5>
    </a>
    <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">J’ai confié à Rémi mon auto pour un nettoyage int./ext. et je suis très satisfait du résultat d’esthétique exécuté sur ma voiture. L’interieur est devenue comme une voiture neuve malgré ses 5 ans! L’extérieur a repris tout son lustre!
En plus d’avoir une auto super propre, « SoClean » a redonné à ma voiture une « valeur ajoutée » pour un échange éventuel. Je suis très content que mon auto soit… « SoClean! ».
Je le recommande sans hésiter.</p>
</div>
<div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Romuald Raffin</h5>
    </a>
    <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Très professionnel a l écoute des besoins du client.  Il a apporté son analyse et fait le travail adéquatement.
Oublié les micros rayures du au lave auto ressorti comme neuve.
Un grand merci</p>
</div>
<div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Marc-Antoine Fortin</h5>
    </a>
    <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Aucun regret d'avoir laisser mon véhicule antique. Travail de professionnel jusqu'aux petits détails. Je recommande sans hésiter.</p>
</div>
    </div>
  </div>
  <br></br>

</div>
{showModal && <div id="background_modal_blur">
        <div id="background_modal">
        <form class="newsletter_subscription">
        <div class="form_newsletter">
        <button class="CloseModal" onClick={onClick}>
            X
          </button>
          <img
              class="logo_modal"
              src={socleanlogo}
            ></img>
            <br/>
          <h3 class="text_newsletter_promotional">
          Nous sommes ravis que vous ayez opté pour nos service ! Veuillez renseigner les champs ci-dessous pour finaliser la demande
          </h3>
          <br/>
          <p class="text-white">Renseignements personnels</p>
          <div className="form_newsletter_name">
            <input
            id = "firstnameforfait"
              onChange={InputNameChange}
              class="inputInscriptionName"
              type="text"
              placeholder="Prénom"
            />
            <input
              onChange={InputNameChange}
              id = "lastnameforfait"
              class="inputInscriptionName"
              type="text"
              placeholder="Nom"
            />
          </div>
          {<span id="firstname-validation-message">{nameError}</span>}
          <div className="form_newsletter_name">
          <input
            onChange={InputEmailChange}
            id = "emailforfait"
            class="inputInscription"
            type="text"
            placeholder="Courriel"
          />
          <input
            id = "telephoneforfait"
            class="inputInscription"
            type="text"
            placeholder="Téléphone"
          />
          </div>
          <p class="text-white">Renseignements supplémentaires</p>
          <textarea
            id = "dispoforfait"
            class="min-w-12"
            rows="3"
            cols="35"
            placeholder="Disponibilité et commentaires"
          />
          <br></br>
          {<span id="email-validation-message">{emailError}</span>}
          <button type="button" class="btnInscription" onClick={SendRequestForfait}> Envoyer ma demande</button>
        </div>
      </form>
        </div>
      </div>}
</div>
)
}