import './forfaits.css'
import './header.css'
import Header from './header';
import Forfaits from './forfaits'
export default function ContactRedirection()
{
    return(
        <div>
            <Header></Header>
            <Forfaits></Forfaits>
        </div>
    );
}