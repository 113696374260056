import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { useRef } from "react";
import { useState } from "react";

export default function Contact() {
  const bodyRef = useRef(null);
  const [showSuccessMessage, setSuccessMessage] = React.useState(false);
  const [showButtonSendRequest, setShowButtonSendRequest] = React.useState(true);
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [files, setFiles] = React.useState([]);

  function InputNameChange(event) {
    const name = event.target.value;
    if (name.length < 2) {
      setNameError("Le nom doit comprendre au moins 2 caractères");
    } else if (/\d/.test(name)) {
      setNameError("Le nom ne doit comprendre que des caracteres alphabetique");
    } else if (!/^[a-zA-Z '-]+$/.test(name)) {
      setNameError("Le nom ne peux pas contenir de caracteres speciaux");
    } else {
      setNameError("");
    }
  }

  function InputEmailChange(event) {
    const email = event.target.value;
    if (email.length < 5) {
      setEmailError("L'adresse courriel doit comprendre au moins 5 caracteres");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError(
        "L'adresse courriel entrée n'est pas dans un format valide"
      );
    } else {
      setEmailError("");
    }
  }

  function InputMessageChange(event) {
    const message = event.target.value;
    const INAPPROPRIATE_WORDS = [
      "Bâtard",
      "Câlice",
      "Calvaire",
      "Calvaire",
      "Ciboire",
      "Crisse",
      "Ostie",
      "estie",
      "osti",
      "esti",
      "asti",
      "Sacrament",
      "Simonaque",
      "Tabarnak",
      "tbk",
      "TBK",
      "Tbk",
      "Gros colon",
      "Suce ma graine",
      "Ostie d’mongol",
      "Criss de tarla",
      "Mangeux de marde",
      "Câlice de chien sale",
      "Ciboire",
    ];
    if (INAPPROPRIATE_WORDS.some((word) => message.includes(word))) {
      setMessageError("Le message ne peux pas contenir de mot innapropriées");
    } else if (message.length < 5) {
      setMessageError("Le message doit comprendre au moins 5 caractères");
    } else if (message.length > 500) {
      setMessageError(
        "Le message ne doit pas comprendre plus de 500 caractères"
      );
    } else if (/[$\\\/<>*^()%#|\[\]]/.test(message)) {
      setMessageError(
        "Le message ne doit pas contenir des caractères spéciaux"
      );
    } else {
      setMessageError("");
    }
  }

  function checkFileSelected(event) {
    setFiles(Array.from(event.target.files));
  }

  function SendData() {
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const requesttype = document.getElementById("requesttype").value;
    const message = document.getElementById("message").value;
    if (
      nameError == "" &&
      emailError == "" &&
      messageError == "" &&
      name.length != 0 &&
      email.length != 0 &&
      message.length != 0
    ) {
      const data = {
        name: name,
        email: email,
        requesttype: requesttype,
        message: message,
      };
      axios
        .post("https://localhost:3001/newrequest", data)
        .then(function (response) {
          const name = (document.getElementById("name").value = "");
          const email = (document.getElementById("email").value = "");
          const requesttype = (document.getElementById("requesttype").value =
            "");
          const message = (document.getElementById("message").value = "");
          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
          }, 15000); // 15 secondes en millisecondes
        });
    }
  }

  return (
    <section
      id="contactsection"
      ref={bodyRef}
      class="bg-white dark:bg-gray-900"
    >
      <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          Nous contacter
        </h2>
        <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
          Prêt à confier votre projet pour lui redonner sa beauté ? Ne tardez
          plus et demandez votre soumission dès maintenant ! Encore hésitant ?
          Faites-nous part de vos préoccupations, nous sommes là pour répondre à
          toutes vos questions.
        </p>
        <form class="space-y-8">
          <div>
            {showSuccessMessage && (
              <div
                id="success_alert"
                class="flex p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  class="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Info</span>
                <div>
                  <span class="font-medium">
                    Votre demande a été envoyée avec succès.
                  </span>{" "}
                  Nous la traiterons dans un délai de 48 heures ouvrables.
                  N'oubliez pas de vérifier vos courriels indésirables
                </div>
              </div>
            )}
            <label
              for="name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Nom
            </label>
            <input
              type="name"
              id="name"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Nom et prénom"
              onChange={InputNameChange}
              required
            />
            {<span id="name-validation-message">{nameError}</span>}
          </div>
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Adresse courriel
            </label>
            <input
              type="email"
              id="email"
              class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="adresse@email.com"
              onChange={InputEmailChange}
              required
            />
            {<span id="email-validation-message">{emailError}</span>}
          </div>
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Type de demande
            </label>
            <select
              id="requesttype"
              class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
            >
              <option>Sélectionner</option>
              <option>Automobile</option>
              <option>Bateau</option>
              <option>Véhicule récréatif (VR)</option>
              <option>Autre</option>
            </select>
          </div>
          <div class="sm:col-span-2">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Votre message
            </label>
            <textarea
              id="message"
              rows="6"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Décrivez-nous vos besoins ou demandes"
              onChange={InputMessageChange}
              required
            ></textarea>
            {<span id="message-validation-message">{messageError}</span>}
          </div>

          {
            <div className="sm:col-span-2">
              <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                htmlFor="file_input"
              >
                Photo(s) ou fichier(s)
              </label>
              <input
                className="hidden block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                multiple
                onChange={checkFileSelected}
              />
              <label className="bg-white px-4 border-2 border-slate-50"
              htmlFor="file_input">
                {files.length === 0
                  ? "Aucun fichier choisi"
                  : `${files.length} fichier(s) choisi(s)`}
              </label>
              <br />
              <br />
              <small className="text-gray-900 dark:text-white">
                Veuillez fournir une photo intérieure et une photo extérieure
                lors de toute demande de renseignement pour nous permettre de
                vous offrir une soumission adaptée.
              </small>
            </div>
          }
          <button
            id="btnsend"
            type="button"
            onClick={SendData}
            class="btn py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-200 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Envoyer
          </button>
        </form>
      </div>
      <footer class="px-4 py-12 mx-auto max-w-7xl bottom">
        <div class="flex flex-col items-start justify-between pt-10 mt-10 border-t border-gray-100 md:flex-row md:items-center">
          <p class="mb-6 text-sm text-left md:mb-0">
            © Copyright 2024 SoClean Esthétique. Tout droit réservé.
          </p>
          <div class="flex justify-center space-x-2"></div>
        </div>
      </footer>
    </section>
  );
}
