import { Link } from 'react-router-dom';
export default function Forfaits()
{
    return(
<div>
<div class="w-full bg-center bg-cover-car">
    <div class="flex items-center justify-center w-full h-screen bg-gray-900 bg-opacity-50 py-12">
      <div class="text-center">
        <div class="container px-4 mx-auto">
          <div class="flex-col text-center">
          <span class="titre text-gray-200 font-semibold uppercase tracking-widest">Automobile</span>
          <h2 class="mt-8 mb-6 h-full w-full text-4xl lg:text-5xl font-bold text-gray-100">Redonnez un éclat à votre voiture</h2>
          <div class="max-w-4xl mx-auto text-center mt-5">
          <Link to="/forfaitsauto" className="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200">Voir les forfaits disponibles</Link>
          
          <a class="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200"href="https://www.facebook.com/socleanesthetique/photos">Voir nos réalisations</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full bg-center bg-cover-boat">
    <div class="flex items-center justify-center w-full h-screen bg-gray-900 bg-opacity-50 py-12">
      <div class="text-center">
        <div class="container px-4 mx-auto">
          <div class="flex-col text-center">
          <span class="titre text-gray-200 font-semibold uppercase tracking-widest">Bateau</span>
          <h2 class="mt-8 mb-6 h-full w-full text-4xl lg:text-5xl font-bold text-gray-100">Redonnez un éclat à votre bateau</h2>
          <div class="max-w-4xl mx-auto text-center mt-5">
          <Link to="/forfaitsbateau" className="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200">Voir les forfaits disponibles</Link>
            <a class="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200"href="https://www.facebook.com/socleanesthetique/photos">Voir nos réalisations</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full bg-center bg-cover-vr">
    <div class="flex items-center justify-center w-full h-screen bg-gray-900 bg-opacity-50 py-12">
      <div class="text-center">
        <div class="container px-4 mx-auto">
          <div class="flex-col text-center">
          <span class="titre text-gray-200 font-semibold uppercase tracking-widest">Véhicules récréatif</span>
          <h2 class="mt-8 mb-6 h-full w-full text-4xl lg:text-5xl font-bold text-gray-100">Redonnez un éclat à votre véhicule récréatif</h2>
          <div class="max-w-4xl mx-auto text-center mt-5">
          <Link to="/forfaitsautre" className="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200">Voir les forfaits disponibles</Link>
                          <a class="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200"
              href="https://www.facebook.com/socleanesthetique/photos">Voir nos réalisations</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full bg-center bg-cover-cie">
    <div class="flex items-center justify-center w-full h-screen bg-gray-900 bg-opacity-50 py-12">
      <div class="text-center">
        <div class="container px-4 mx-auto">
          <div class="flex-col text-center">
          <span class="titre text-gray-200 font-semibold uppercase tracking-widest">Flotte d'entreprise</span>
          <h2 class="mt-8 mb-6 h-full w-full text-4xl lg:text-5xl font-bold text-gray-100">Redonnez un éclat à vos véhicules d'entreprise</h2>
          <div class="max-w-4xl mx-auto text-center mt-5">
          <Link to="/forfaitscie" className="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200">Voir les forfaits disponibles</Link>
                          <a class="btn-info inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-900 text-white rounded hover:bg-gray-100 hover:text-black transition duration-200"
              href="https://www.facebook.com/socleanesthetique/photos">Voir nos réalisations</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
)
}