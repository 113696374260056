import './contact.css'
import './header.css'
import Header from './header';
import Contact from './contact'
export default function ContactRedirection()
{
    return(
        <div>
            <Header></Header>
            <Contact></Contact>
        </div>
    );
}