import React from 'react';
import ReactDOM from 'react-dom/client';
import './header.css';
import './home.css';
import './gallery.css'
import './contact.css'
import './pricing.css'
import './before_after_slider.css'
import Footer from './footer';
import Contact from './contact';
import Home from './home';
import Header from './header';
import Pricing from './pricing';
import Gallery from './gallery';
import BeforeAfterSlider from './before_after_slider';
export default function Main()
{
    return (
    <div>
      <Header/>
      <div id='content'>
        <Home/>
        <Pricing/>
        <BeforeAfterSlider/>
        <Gallery/>
      </div>
    </div>
    );
}
