import React from 'react';
import ReactDOM from 'react-dom/client';
export default function home() {
    return (
      <section class="mb-20 text-gray-800 text-center features">
      <h2 class="text-3xl font-bold mb-10">Pourquoi choisir SoClean ?</h2>
  
      <div class="grid lg:gap-x-10 lg:grid-cols-3 features">
        <div class="mb-12 lg:mb-0">
          <div class="rounded-lg shadow-lg h-full block bg-white">
            <div class="flex justify-center">
              <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                <svg class="w-8 h-8 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="p-6">
              <h5 class="text-lg font-semibold mb-4">Oser échanger avec nous</h5>
              <p>
              Nous sommes ravis de répondre à toutes vos questions, sans aucun jugement, quel que soit votre niveau de connaissances. Notre équipe est là pour vous conseiller et vous orienter vers le service le plus adapté à vos besoins.
              </p>
            </div>
          </div>
        </div>
  
        <div class="mb-12 lg:mb-0">
          <div class="rounded-lg shadow-lg h-full block bg-white">
            <div class="flex justify-center">
              <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                <svg class="w-8 h-8 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="p-6">
              <h5 class="text-lg font-semibold mb-4">L’expérience Premium</h5>
              <p>
              Anticipez un service sur mesure cinq étoiles. Notre engagement : offrir une expérience exceptionnelle à chaque clients.
              </p>
            </div>
          </div>
        </div>
  
        <div class="">
          <div class="rounded-lg shadow-lg h-full block bg-white">
            <div class="flex justify-center">
              <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                <svg class="w-8 h-8 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M505.12019,19.09375c-1.18945-5.53125-6.65819-11-12.207-12.1875C460.716,0,435.507,0,410.40747,0,307.17523,0,245.26909,55.20312,199.05238,128H94.83772c-16.34763.01562-35.55658,11.875-42.88664,26.48438L2.51562,253.29688A28.4,28.4,0,0,0,0,264a24.00867,24.00867,0,0,0,24.00582,24H127.81618l-22.47457,22.46875c-11.36521,11.36133-12.99607,32.25781,0,45.25L156.24582,406.625c11.15623,11.1875,32.15619,13.15625,45.27726,0l22.47457-22.46875V488a24.00867,24.00867,0,0,0,24.00581,24,28.55934,28.55934,0,0,0,10.707-2.51562l98.72834-49.39063c14.62888-7.29687,26.50776-26.5,26.50776-42.85937V312.79688c72.59753-46.3125,128.03493-108.40626,128.03493-211.09376C512.07526,76.5,512.07526,51.29688,505.12019,19.09375ZM384.04033,168A40,40,0,1,1,424.05,128,40.02322,40.02322,0,0,1,384.04033,168Z" />
                </svg>
              </div>
            </div>
            <div class="p-6">
              <h5 class="text-lg font-semibold mb-4">Un estimé selon vos besoins</h5>
              <p>
              Avec SoClean, nous nous engageons à poser les bonnes questions pour vous fournir une estimation à la hauteur de vos ambitions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
  }