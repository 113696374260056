import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import './App.css';
import './realisations.css'
import './unsubscribe.css'
import './header.css'
import './contact.css'
import './Jobstatus.css'
import Main from './main'
import Forfaits from './forfaits'
import AutoForfaits from './forfaits2'
import BateauForfaits from './forfaits3'
import AutreForfaits from './forfaits4'
import CieForfaits from './forfaits5'
import ForfaitsDirect from './forfaitdirect'
import EstimationTools from "./estimation_tools"
import Header from "./header";
import NotFound from "./404"
import Realisation from "./realisations"
import ContactRedirect from "./contactredirect"
import Unsubscribe from "./unsubscribe"
import JobStatus from './Jobstatus';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/estimation",
    element: <EstimationTools/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/realisations",
    element: <Realisation/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/contact",
    element: <ContactRedirect/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/forfaits",
    element: <ForfaitsDirect/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/forfaitsauto",
    element: <AutoForfaits/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/forfaitsbateau",
    element: <BateauForfaits/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/forfaitsautre",
    element: <AutreForfaits/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/forfaitscie",
    element: <CieForfaits/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/status",
    element: <JobStatus/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/unsubscribe",
    element: <Unsubscribe/>,
    errorElement: <NotFound/>,
  },
  {
    path: "/404",
    element: <NotFound/>
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
